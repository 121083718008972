
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { IGetProductForViewDto } from "@/shared/service-proxies/service-proxies";
import { ProductsFilter } from "@/models";
import AJLoader from "@/components/AjaxLoader.vue";
import useProducts from "@/composables/useProducts";
import DeleteItemService from "@/core/services/DeleteItemService";
import { useStore } from "vuex";
import { formatCurrency } from "@/core/services/HelperServices";

export default defineComponent({
  name: "Products",
  components: {
    Datatable,
    AJLoader,
  },
  setup() {
    const { products, getProducts, deleteProduct } = useProducts();

    let loading = ref<boolean>(true);
    const showAdvanceFilter = ref<boolean>(false);

    const defaultFilter = ref({
      filter: "",
      skipCount: 0,
      isActiveFilter: -1,
      maxResultCount: 1000,
    } as unknown as ProductsFilter);

    const tableHeader = ref([
      {
        name: "Actions",
        key: "actions",
      },
      {
        name: "Product Code",
        key: "productCode",
        sortable: true,
      },
      {
        name: "Product Name",
        key: "productName",
        sortable: true,
      },
      {
        name: "Short Name",
        key: "shortName",
        sortable: true,
      },
      {
        name: "Display Order",
        key: "displayOrder",
        sortable: true,
      },
      {
        name: "Is Active",
        key: "isActive",
        sortable: true,
      },
      {
        name: "Unit Price",
        key: "unitPrice",
        sortable: true,
      },
      {
        name: "Unit Name",
        key: "productUnitName",
        sortable: true,
      },
      {
        name: "Category Name",
        key: "productCategoryName",
        sortable: true,
      },
      {
        name: "RCS8Code",
        key: "RCS8Code",
        sortable: true,
      },
    ]);

    const tableData = ref<Array<IGetProductForViewDto>>([]);

    const initialData = ref<Array<IGetProductForViewDto>>([]);
    const breadcrumbs = {
      title: "Products",
      breadcrumbs: ["Products"],
    };

    const store = useStore();
    const config = store.getters.getConfigurations;
    const permissions = config.result.auth.grantedPermissions;
    onMounted(async () => {
      setCurrentPageBreadcrumbs("Products", ["Products header info"]);

      await getData();
    });

    const deleteHandler = async (id: string) => {
      const res = await DeleteItemService.delete(id as string, deleteProduct);

      if (res) {
        console.log("deleting...");
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].product.id === id) {
            tableData.value.splice(i, 1);
          }
        }
      }
    };

    const getData = async () => {
      loading.value = true;

      await getProducts(defaultFilter.value as unknown as ProductsFilter);
      console.log(products.value);

      tableData.value = products.value;

      initialData.value.splice(0, tableData.value.length, ...tableData.value);

      loading.value = false;
    };

    const searchProduct = async () => {
      await getData();
    };

    return {
      defaultFilter,
      loading,
      tableData,
      tableHeader,
      showAdvanceFilter,
      deleteHandler,
      searchProduct,
      breadcrumbs,
      permissions,
      formatCurrency,
    };
  },
});
